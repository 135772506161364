
export default {
  props: ["value"],
  data() {
    return {
      lang: this.$router.currentRoute.params.lang,
    };
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
    },
  },
};
